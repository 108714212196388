import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="card">
        <div className="card-content">
          <h1 className="title">Entschuldigung,</h1>
          <h2 className="subtitle is-5">
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            leider konnten wir diese Seite nicht finden.
          </h2>
          <Link to="/">Zurück zur Hauptseite</Link>.
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
